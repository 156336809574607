import React from 'react';
import rightHive from './rightBee.png';
import leftHive from './leftBee.png';
import styles from './bulkBanner.module.scss';

export const BulkBanner = () => {
  return (
    <div className={styles.bannerBackground}>
      <img className={styles.leftHive} src={leftHive}></img>
      <div className={styles.offerText}>
        FOR BULK ORDERS CONTACT US ON OUR WHATSAPP{' '}
        <a href="https://wa.link/qo63cl">+918957849848</a>
      </div>
      <img className={styles.rightHive} src={rightHive}></img>
    </div>
  );
};
